import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { initAccountInfo } from '_store/account'
import { getAccountInfoApi } from '_api'
import { initToken } from '_api/BaseApi'

import AuthService from './AuthService';

const useInitAuth = () => {
  const dispatch = useDispatch()
  const [authStatus, setAuthStatus] = useState({status: 'loading'})

  if(authStatus.status === 'loading') {
    const token = AuthService.token();
    initToken(token)
    getAccountInfoApi().then(res => {
      if (res.status) {
        const own_instance_id = (res.data.own_instance || {}).instance_id
        dispatch(initAccountInfo({
          accountInfo: {
            account: res.data.account,
            user_id: res.data.user_id,
            updated_at: res.data.updated_at
          },
          token: token,
          instances: res.data.associated_instances.map(item => ({
            ...item,
            own: own_instance_id === item.instance_id
          })),
          demo_instances: res.data.demo_instances.map(item =>({
            ...item,
            own: own_instance_id === item.instance_id
          })),
          default_instance_id: (res.data.default_instance || {}).instance_id
        }))
        setAuthStatus({status: 'logined'})
      } else {
        setAuthStatus({status: 'error', message: 'Something is Error'})
      }
    })
  }

  return authStatus
};

export default useInitAuth
