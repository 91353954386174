import React from 'react'
import { useTranslation } from 'react-i18next'

import { ConfigProvider } from 'antd'
import enUS from 'antd/lib/locale/en_US'
import zhCN from 'antd/lib/locale/zh_CN'

import ResultPage from '_pages/ResultPage'
import { LoadingView } from '_components/elements/GlobalLoading'

import useInitAuth from '_utils/useInitAuth'
import Router from '_config/router'

const App = () => {
  const { i18n } = useTranslation()
  const initAuthStatus = useInitAuth()

  if (initAuthStatus.status === 'loading') {
    return <LoadingView />
  }

  if (initAuthStatus.status === 'error') {
    return <ResultPage status="error" title="Oops" subTitle={initAuthStatus.message} />
  }

  return (
    <>
      <ConfigProvider locale={i18n.resolvedLanguage === 'en' ? enUS : zhCN}>
        <Router />
      </ConfigProvider>
    </>
  )
}

export default App
