import axios from 'axios'
import settings from '_config/settings'
import Qs from 'qs'

const API = axios.create({
  baseURL: settings.serverApiUrl,
  headers: { 'Content-Type': 'application/json;charset=UTF-8' },
  paramsSerializer: function(params) {
    return Qs.stringify(params, { indices: false })
  }
  // timeout: 10000,
})

API.interceptors.response.use(
  (res) => {
    return {
      status: true,
      data: res.data,
      pagination: JSON.parse(res.headers['x-pagination'] || '{}')
    }
  },
  (error) => {
    const status_code = error.response ? error.response.status : 500
    if (status_code === 401) {
      if (!error.config.skip_login) {
        window.location.replace("/login")
      }
      return {status: false, message: "unauthorized"}
    }
    return ({
      status: false,
      message: error.response ? (
        error.response.data ? (
          error.response.data.message || error.response.data.status
        ) : error.response
      ) : 'Network Error'
    })
  }
)

const initToken = (token) => {
  API.defaults.headers.common[
    process.env.AUTH_MODE === 'authing' ? 'AuthingAuth' : 'Authorization'
  ] = `Bearer ${token}`
}

const callPost = (api, data, config = null) => {
  return API.post(api, data && data, config && { ...config })
}

const callGet = (api, config = null) => {
  return API.get(api, config && { ...config })
}

const callPut = (api, data, config = null) => {
  return API.put(api, data && data, config && { ...config })
}

const callPatch = (api, data, config = null) => {
  return API.patch(api, data && data, config && { ...config })
}

const callDel = (api, config = null) => {
  return API.delete(api, config && { ...config })
}

export { initToken, callPost, callGet, callPatch, callPut, callDel }
