import { lazy } from 'react'

export const ManageRouteConfig = [
  {
    key: 'manage.home',
    tagName: [{type: 'translation', value: 'nav.home'}],
    path: '/manage/home',
    exact: true,
    component: lazy(() => import('_pages/Manage/Home')),
    pageTitle: [
      {name: 'nav.home'}
    ]
  },
  {
    key: 'manage.forecast',
    tagName: [{type: 'translation', value: 'nav.forecast_replenishment.forecast'}],
    path: '/manage/forecast',
    exact: true,
    component: lazy(() => import('_pages/Manage/Forecast')),
    pageTitle: [
      {name: 'nav.forecast_replenishment.common'},
      {name: 'nav.forecast_replenishment.forecast'}
    ]
  },
  {
    key: 'manage.replenishment',
    tagName: [{type: 'translation', value: 'nav.forecast_replenishment.replenishment'}],
    path: '/manage/replenishment',
    exact: true,
    component: lazy(() => import('_pages/Manage/Replenishment')),
    pageTitle: [
      {name: 'nav.forecast_replenishment.common'},
      {name: 'nav.forecast_replenishment.replenishment'}
    ]
  },
  {
    key: 'manage.forecast.items.id',
    tagName: [
      {type: 'translation', value: 'nav.forecast_replenishment.replenishment'},
      {type: 'state', value: 'sku'}
    ],
    path: '/manage/forecast/:id',
    exact: true,
    component: lazy(() => import('_pages/Manage/ItemForecastDetail')),
    pageTitle: [
      {name: 'nav.forecast_replenishment.common'},
      {name: 'nav.forecast_replenishment.replenishment'},
      {name: 'nav.forecast_replenishment.item_detail'}
    ]
  },
  {
    key: 'manage.vendors',
    tagName: [{type: 'translation', value: 'nav.po_vendors.vendors'}],
    path: '/manage/vendors',
    exact: true,
    component: lazy(() => import('_pages/Manage/Vendors')),
    pageTitle: [
      {name: 'nav.po_vendors.common'},
      {name: 'nav.po_vendors.vendors'}
    ]
  },
  {
    key: 'manage.data.items.id',
    tagName: [{type: 'translation', value: 'nav.po_vendors.vendor_items'}, {type: 'state', value: 'name'}],
    path: '/manage/vendors/:id/items',
    exact: true,
    component: lazy(() => import('_pages/Manage/Vendors/Items')),
    pageTitle: [
      {name: 'nav.po_vendors.common'},
      {name: 'nav.po_vendors.vendors'},
      {name: 'nav.po_vendors.vendor_items'}
    ]
  },
  {
    key: 'manage.po',
    tagName: [{type: 'translation', value: 'nav.po_vendors.po'}],
    path: '/manage/po',
    exact: true,
    component: lazy(() => import('_pages/Manage/PurchaseOrders')),
    pageTitle: [
      {name: 'nav.po_vendors.common'},
      {name: 'nav.po_vendors.po'}
    ]
  },
  {
    key: 'manage.po.new',
    tagName: [{type: 'translation', value: 'nav.po_vendors.new_po'}],
    path: '/manage/po/new',
    exact: true,
    component: lazy(() => import('_pages/Manage/PurchaseOrders/NewPurchaseOrder')),
    pageTitle: [
      {name: 'nav.po_vendors.common'},
      {name: 'nav.po_vendors.po'},
      {name: 'nav.po_vendors.new_po'}
    ]
  },
  {
    key: 'manage.po.edit',
    tagName: [{type: 'translation', value: 'nav.po_vendors.edit_po'}, {type: 'state', value: 'number'}],
    path: '/manage/po/edit/:id',
    exact: true,
    component: lazy(() => import('_pages/Manage/PurchaseOrders/EditPurchaseOrder')),
    pageTitle: [
      {name: 'nav.po_vendors.common'},
      {name: 'nav.po_vendors.po'},
      {name: 'nav.po_vendors.edit_po'}
    ]
  },
  {
    key: 'manage.data.items',
    tagName: [{type: 'translation', value: 'nav.data.items'}],
    path: '/manage/data/items',
    exact: true,
    component: lazy(() => import('_pages/Manage/Items')),
    pageTitle: [
      {name: 'nav.data.common'},
      {name: 'nav.data.items'}
    ]
  },
  {
    key: 'manage.data.items.id',
    tagName: [{type: 'translation', value: 'nav.data.items'}, {type: 'state', value: 'sku'}],
    path: '/manage/data/items/:id',
    exact: true,
    component: lazy(() => import('_pages/Manage/Items/Detail')),
    pageTitle: [
      {name: 'nav.data.common'},
      {name: 'nav.data.items'},
      {name: 'nav.data.item_detail'}
    ]
  },
  {
    key: 'manage.data.orders',
    tagName: [{type: 'translation', value: 'nav.data.orders'}],
    path: '/manage/data/orders',
    exact: true,
    component: lazy(() => import('_pages/Manage/Orders')),
    pageTitle: [
      {name: 'nav.data.common'},
      {name: 'nav.data.orders'}
    ]
  },
  {
    key: 'manage.data.stock',
    tagName: [{type: 'translation', value: 'nav.data.stock'}],
    path: '/manage/data/stock',
    exact: true,
    component: lazy(() => import('_pages/Manage/Stock')),
    pageTitle: [
      {name: 'nav.data.common'},
      {name: 'nav.data.stock'}
    ]
  },
  {
    key: 'manage.matching.store_items',
    tagName: [{type: 'translation', value: 'nav.matching.store_items'}],
    path: '/manage/matching/store-items',
    exact: true,
    component: lazy(() => import('_pages/Manage/Matching/StoreItems')),
    pageTitle: [
      {name: 'nav.matching.common'},
      {name: 'nav.matching.store_items'}
    ]
  },
  {
    key: 'manage.matching.rules',
    tagName: [{type: 'translation', value: 'nav.matching.rules'}],
    path: '/manage/matching/rules',
    exact: true,
    component: lazy(() => import('_pages/Manage/Matching/Rules')),
    pageTitle: [
      {name: 'nav.matching.common'},
      {name: 'nav.matching.rules'}
    ]
  },
  {
    key: 'manage.settings.warehouses',
    tagName: [{type: 'translation', value: 'nav.settings.warehouses'}],
    path: '/manage/settings/warehouses',
    exact: true,
    component: lazy(() => import('_pages/Manage/Warehouses')),
    pageTitle: [
      {name: 'nav.settings.common'},
      {name: 'nav.settings.warehouses'}
    ]
  },
  {
    key: 'manage.settings.stores',
    tagName: [{type: 'translation', value: 'nav.settings.stores'}],
    path: '/manage/settings/stores',
    exact: true,
    component: lazy(() => import('_pages/Manage/Stores')),
    pageTitle: [
      {name: 'nav.settings.common'},
      {name: 'nav.settings.stores'}
    ]
  },
  {
    key: 'manage.settings.forecast',
    tagName: [{type: 'translation', value: 'nav.settings.forecast'}],
    path: '/manage/settings/forecast',
    exact: true,
    component: lazy(() => import('_pages/Manage/ForecastConfig')),
    pageTitle: [
      {name: 'nav.settings.common'},
      {name: 'nav.settings.forecast'}
    ]
  },
  {
    key: 'manage.settings.sub_accounts',
    tagName: [{type: 'translation', value: 'nav.settings.sub_accounts'}],
    path: '/manage/settings/sub-accounts',
    exact: true,
    component: lazy(() => import('_pages/Manage/SubAccounts')),
    pageTitle: [
      {name: 'nav.settings.common'},
      {name: 'nav.settings.sub_accounts'}
    ]
  }
]

const RouteConfig = [
  {
    key: 'manage',
    path: '/manage/',
    strict: true,
    component: lazy(() => import('_components/layouts/ManagePageLayout'))
  }
]

export default RouteConfig
