import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Spin } from 'antd'

export const LoadingView = ({ message = "", ...props}) => {
  return (
    <div style={{
      position: 'fixed',
      zIndex: 9999,
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '100vh',
      background: 'rgba(255,255,255,0.3)'
    }}>
      <Spin size="large" tip={message}/>
    </div>
  )
}

export const GlobalLoadingView = forwardRef(({
  ...props
}, ref) => {
  const [show, setShow] = useState(false)

  useImperativeHandle(ref, () => ({
    show: (message) => {
      setShow(message ? message : true)
    },
    hide: () => {
      setShow(false)
    }
  }))

  if (!show) {
    return null
  }
  return <LoadingView message={show} />
})

class GlobalLoaderUtil {
  constructor() {
    this.instance = null
  }
  static getInstance() {
    if (!this.instance) {
      this.instance = new GlobalLoaderUtil()
    }
    return this.instance
  }
  setGlobalLoader(globalLoaderRef) {
    this.loader = globalLoaderRef
  }
  show(message) {
    if (this.loader) {
      this.loader.show(message)
    }
  }
  hide() {
    if (this.loader) {
      this.loader.hide()
    }
  }
}

const GlobalLoader = GlobalLoaderUtil.getInstance()
export default GlobalLoader
