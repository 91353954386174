import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import RouteConfig from './RouteConfig'

const AppRouter = () => {

  return (
    <BrowserRouter>
      <Switch>
        {RouteConfig.map((route) => (
          <Route key={route.key} {...{
            path: route.path,
            ...(route.exact ? { exact: true } : null),
            ...(route.strict ? { strict: true } : null),
            render: (props) => (
              <route.component {...props} />
            )
          }} />
        ))}
        <Redirect to={"/manage/home"} />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter
