import { Result } from 'antd'

const ResultPage = ({chidren, ...props}) => {
  return (
    <div style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <Result {...props}>
        {chidren}
      </Result>
    </div>
  )
}

export default ResultPage
