import { callPost, callGet, callPatch, callPut, callDel } from './BaseApi'

//Account
export const getAccountInfoApi = () => {
  return callGet('me/')
}

export const changeDefaultInstanceApi = ({id}) => {
  return callPatch('me/', { "default_instance_id": id })
}

//Instance
export const getInstanceApi = ({instance_id}) => {
  return callGet(`me/instances`, {
    headers: { "instance-id": instance_id }
  })
}

export const createInstanceApi = ({name}) => {
  return callPost('me/instances', { name: name })
}

export const updateInstanceApi = ({
  id,
  name,
  global_currency_exchange_rate_setting
}) => {
  return callPatch(`me/instances`, {
    ...( name ? { name: name } : null ),
    ...( global_currency_exchange_rate_setting ? {
      global_currency_exchange_rate_setting: global_currency_exchange_rate_setting
    } : null )
  }, { headers: { "instance-id": id } })
}

//Instance Link(Sub Account)
export const getInstanceLinksApi = ({instance_id, page = 1, page_size = 10}) => {
  return callGet('settings/user_instance_link', {
    params: { page: page, page_size: page_size },
    headers: { "instance-id": instance_id }
  })
}

export const createInstanceLinkApi = ({instance_id, account, name, full_access = true}) => {
  return callPost('settings/user_instance_link', {
    account: account,
    name: name,
    full_access: full_access
  }, { headers: { "instance-id": instance_id } })
}

export const updateInstanceLinkApi = ({instance_id, id, active = null, name, full_access}) => {
  return callPatch(`settings/user_instance_link/${id}`, {
    ...( active !== null ? { active: active } : null ),
    ...( name ? { name: name } : null ),
    ...( full_access !== null ? { full_access: full_access } : null )
  }, { headers: { "instance-id": instance_id } })
}

//Connections
export const getConnectionsApi = ({instance_id, page = 1, page_size = 10}) => {
  return callGet('connections/', {
    params: { page: page, page_size: page_size },
    headers: { "instance-id": instance_id }
  })
}

export const createConnectionApi = ({
  instance_id,
  name,
  base_currency,
  config = {},
  connection_type = "SHOPIFY",
  auth = {}
}) => {
  return callPost('connections/', {
    ...( name ? { name: name } : null ),
    ...( base_currency ? { base_currency: base_currency } : null ),
    config: config, //store_name, start_date, file_list, path_prefix, mode
    connection_type: connection_type,
    auth: auth //api_password
  }, { headers: { "instance-id": instance_id } })
}

export const updateConnectionApi = ({
  instance_id,
  id,
  enabled,
  name,
  config,
  base_currency
}) => {
  return callPatch(`connections/${id}`, {
    ...( enabled ? { enabled: enabled } : null ),
    ...( name ? { name: name } : null ),
    ...( config ? { config: config } : null ),
    ...( base_currency ? { base_currency: base_currency } : null )
  }, { headers: { "instance-id": instance_id } })
}

export const deleteConnectionApi = ({
  instance_id,
  connection_id
}) => {
  return callDel(`connections/${connection_id}`, {
    headers: { "instance-id": instance_id }
  })
}

export const getConnectionPresignedUrlApi = ({
  instance_id,
  file_type,
  path_prefix,
  file_list, // item_file, site_file, vendor_file, inventory_file, sale_order_file, sale_file, stock_order_file, stock_order_item_file
  client_method //put_object, get_object
}) => {
  return callGet('connections/presigned_url', {
    params: {
      client_method: client_method,
      ...( file_type ? { file_type: file_type } : null ),
      ...( path_prefix ? { path_prefix: path_prefix } : null ),
      ...( file_list ? { file_list: file_list } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

//Connections settings
export const getConnectionConsentUrlApi = ({instance_id, connection_type, name}) => {
  return callGet('settings/connections/consent_url', {
    params: { connection_type: connection_type, name: name },
    headers: { "instance-id": instance_id }
  })
}

//Warehouses
export const getWarehousesApi = ({instance_id, page = 1, page_size = 10}) => {
  return callGet('sites/', {
    params: { page: page, page_size: page_size },
    headers: { "instance-id": instance_id }
  })
}

//Item
export const getItemsApi = ({
  instance_id,
  type,
  site_id,
  kind,
  status,
  search_key_words, //name, sku, bar_code
  page = 1,
  page_size = 10
}) => {
  return callGet('items/', {
    params: {
      page: page,
      page_size: page_size,
      ...( type ? { type: type } : null ),
      ...( site_id ? { site_id: site_id } : null ),
      ...( kind ? { kind: kind } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null ),
      ...( status ? { status: status } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const getItemApi = ({id, instance_id}) => {
  return callGet(`items/${id}`, {
    headers: { "instance-id": instance_id }
  })
}

export const updateItemApi = ({id, instance_id, note, status}) => {
  return callPatch(`items/${id}`, {
    ...( status ? { status: status } : null ),
    ...( note ? { note: note } : null )
  }, { headers: { "instance-id": instance_id }})
}

export const getItemRuleApi = ({instance_id}) => {
  return callGet(`settings/item_rule`, { headers: { "instance-id": instance_id }})
}

export const setItemRuleApi = ({instance_id, enabled, rule_config}) => {
  // rule_config example: {
  //   hot_sale_label_rule: {
  //     latest_day_period: 1,
  //     sale_num_lower_bound: 50
  //   },
  //   slow_sale_label_rule: {
  //     stock_rule_operator: AND,
  //     sale_num_upper_bound: 1,
  //     stock_num_lower_bound: 50,
  //     latest_day_period: 31
  //   },
  //   new_sale_label_rule: {
  //     day_from_created: 14
  //   }
  // }
  return callPatch(`settings/item_rule`, {
    ...( enabled !== null ? { enabled: enabled } : null ),
    ...( rule_config ? { rule_config: rule_config } : null )
  }, { headers: { "instance-id": instance_id } })
}

//Sales
export const getSalesApi = ({
  instance_id,
  site_id,
  item_id_list,
  sale_start_date,
  sale_end_date,
  group_type = 'MONTHLY'
}) => {
  return callGet('sales/', {
    params: {
      site_id: site_id,
      item_id_list: item_id_list instanceof Array ? item_id_list : [item_id_list],
      group_type: group_type,
      ...( sale_start_date ? { sale_start_date: sale_start_date } : null ),
      ...( sale_end_date ? { sale_end_date: sale_end_date } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

//Orders
export const getOrdersApi = ({
  instance_id,
  connection_type,
  connection_id,
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet('sales_orders/', {
    params: {
      page: page,
      page_size: page_size,
      ...( connection_type ? { connection_type: connection_type } : null ),
      ...( connection_id ? { connection_id: connection_id } : null ),
      ...( search_key_words ? {
        item_search_key_words: search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const updateOrdersApi = ({ instance_id, id, enabled }) => {
  return callPatch(`sales_orders/${id}`, {
    enabled: enabled
  }, { headers: { "instance-id": instance_id }})
}

//inventories
export const getStockApi = ({
  instance_id,
  site_id,
  item_id_list,
  item_search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet('inventories/', {
    params: {
      page: page,
      page_size: page_size,
      site_id: site_id,
      ...( item_id_list ? {
        item_id_list: item_id_list instanceof Array ? item_id_list : [item_id_list]
      } : null ),
      ...( item_search_key_words ? {
        item_search_key_words: item_search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

//matching
export const getItemsMatchingApi = ({
  instance_id,
  connection_type,
  search_key_words,
  status,
  connection_id,
  page = 1,
  page_size = 10
}) => {
  return callGet('item_matching/listing_items/', {
    params: {
      page: page,
      page_size: page_size,
      ...( status ? { item_matching_status: status } : null ),
      ...( connection_type ? { connection_type: connection_type } : null ),
      ...( connection_id ? { connection_id: connection_id } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const updateItemsMatchingApi = ({
  instance_id,
  item_id,
  note,
  action, //UNBIND CHANGE_MATCHING CONFIRM_MATCHING
  master_sku_item_id
}) => {
  return callPut(`item_matching/listing_items/${item_id}/apply_action`, {
    ...( note ? { note: note } : null ),
    ...( action ? { action: action } : null ),
    ...( master_sku_item_id ? { master_sku_item_id: master_sku_item_id } : null )
  }, { headers: { "instance-id": instance_id } })
}

export const getItemsMatchingLinkableApi = ({
  item_id,
  instance_id,
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet(`item_matching/listing_items/${item_id}/linkable_master_sku_items`, {
    params: {
      page: page,
      page_size: page_size,
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const getMatchingRulesApi = ({
  instance_id,
  type,
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet('item_matching/matching_items', {
    params: {
      page: page,
      page_size: page_size,
      ...( type ? { type: type } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const getMatchingRulesLinkableApi = ({
  item_id,
  instance_id,
  connection_id,
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet(`item_matching/matching_items/${item_id}/linkable_items`, {
    params: {
      page: page,
      page_size: page_size,
      ...( connection_id ? { connection_id: connection_id } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const addMatchingRulesLinkedApi = ({
  item_id,
  instance_id,
  link_item_id
}) => {
  return callPost(`item_matching/matching_items/${item_id}/linked_items`, {
    link_item_id_list: link_item_id instanceof Array ? link_item_id : [link_item_id]
  }, { headers: { "instance-id": instance_id }})
}

export const deleteMatchingRulesLinkedApi = ({
  item_id,
  instance_id,
  linked_item_id
}) => {
  return callDel(`item_matching/matching_items/${item_id}/linked_items/${linked_item_id}`, {
    headers: { "instance-id": instance_id }
  })
}

export const autoMatchingApi = ({
  instance_id,
  sku_extract_regex
}) => {
  return callPost('item_matching/auto_matching/', {
    sku_extract_regex: sku_extract_regex
  }, { headers: { "instance-id": instance_id }})
}

//Vendors
export const getVendorsApi = ({instance_id, page = 1, page_size = 10}) => {
  return callGet('vendors/', {
    params: { page: page, page_size: page_size },
    headers: { "instance-id": instance_id }
  })
}

export const createVendorApi = ({
  instance_id,
  name,
  phone_number,
  contact_name,
  currency
}) => {
  return callPost('vendors/', {
    name: name,
    phone_number: phone_number,
    enabled: true,
    contact_name: contact_name,
    currency: currency
  }, { headers: { "instance-id": instance_id } })
}

export const updateVendorApi = ({
  instance_id,
  vendor_id,
  enabled,
  name,
  phone_number,
  contact_name,
  currency
}) => {
  return callPatch(`vendors/${vendor_id}`, {
    ...( enabled !== null ? { enabled: enabled } : null ),
    ...( name ? { name: name } : null ),
    ...( phone_number ? { phone_number: phone_number } : null ),
    ...( contact_name ? { contact_name: contact_name } : null ),
    ...( currency ? { currency: currency } : null )
  }, { headers: { "instance-id": instance_id } })
}

export const getVendorItemsApi = ({
  instance_id,
  vendor_id,
  type,
  status,
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet(`vendors/${vendor_id}/items`, {
    params: {
      page: page,
      page_size: page_size,
      ...( type ? { type: type } : null ),
      ...( status ? { status: status } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,，]\s*/g, " | ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const getVendorLinkableItemsApi = ({
  instance_id,
  vendor_id,
  type,
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet(`vendors/${vendor_id}/linkable_items`, {
    params: {
      page: page,
      page_size: page_size,
      ...( type ? { type: type } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const linkVendorItemApi = ({instance_id, vendor_id, item_id}) => {
  return callPost(`vendors/${vendor_id}/items`, {
    item_id: item_id
  }, { headers: { "instance-id": instance_id } })
}

export const updateVendorItemApi = ({
  instance_id,
  vendor_id,
  item_id,
  notes,
  cost_price
}) => {
  return callPatch(`vendors/${vendor_id}/items/${item_id}`, {
    ...( notes ? { notes: notes } : null ),
    ...( cost_price ? { cost_price: cost_price } : null )
  }, {
    headers: { "instance-id": instance_id }
  })
}

export const unlinkVendorItemApi = ({instance_id, vendor_id, item_id}) => {
  return callDel(`vendors/${vendor_id}/items/${item_id}`, {
    headers: { "instance-id": instance_id }
  })
}

//Stock Orders
export const getStockOrdersApi = ({
  instance_id,
  type = 'PURCHASE_ORDER',
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet('stock_orders/', {
    params: {
      page: page,
      page_size: page_size,
      ...( type ? { type: type } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,，]\s*/g, " | ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const getStockOrderByIdApi = ({
  instance_id,
  stock_order_id
}) => {
  return callGet(`stock_orders/${stock_order_id}`, {
    headers: { "instance-id": instance_id }
  })
}

export const createStockOrderApi = ({
  instance_id,
  to_site_id,
  vendor_id,
  from_site_id,
  items, //[{order_quantity, item_id, cost_price, landing_cost_price}]
  expected_date,
  type = 'PURCHASE_ORDER'
}) => {
  return callPost('stock_orders/', {
    to_site_id: to_site_id,
    vendor_id: vendor_id,
    items: items instanceof Array ? items : [items],
    expected_date: expected_date,
    type: type,
    ...( from_site_id ? { from_site_id: from_site_id } : null )
  }, { headers: { "instance-id": instance_id } })
}

export const updateStockOrderApi = ({
  instance_id,
  stock_order_id,
  to_site_id,
  vendor_id,
  from_site_id,
  items, //[{order_quantity, item_id, cost_price, landing_cost_price}]
  expected_date,
  type = 'PURCHASE_ORDER'
}) => {
  return callPatch(`stock_orders/${stock_order_id}`, {
    to_site_id: to_site_id,
    vendor_id: vendor_id,
    items: items instanceof Array ? items : [items],
    expected_date: expected_date,
    type: type,
    ...( from_site_id ? { from_site_id: from_site_id } : null )
  }, { headers: { "instance-id": instance_id } })
}

export const receiveStockOrderApi = ({
  instance_id,
  stock_order_id,
  items //[{received_date, item_id, received_quantity}]
}) => {
  return callPost(`stock_orders/${stock_order_id}/received`, {
    items: items instanceof Array ? items : [items]
  }, { headers: { "instance-id": instance_id } })
}

//Overview
export const getStockOverviewApi = ({instance_id, site_id}) => {
  return callGet('overviews/stock', {
    params: { site_id: site_id },
    headers: { "instance-id": instance_id }
  })
}

export const getPurchaseOverviewApi = ({
  instance_id,
  site_id,
  start_date,
  end_date
}) => {
  return callGet('overviews/purchase', {
    params: {
      site_id: site_id,
      start_date: start_date,
      end_date: end_date
    },
    headers: { "instance-id": instance_id }
  })
}

export const getPurchaseByVendorApi = ({
  instance_id,
  site_id,
  start_date,
  end_date,
  top_N = 5
}) => {
  return callGet('overviews/purchase_by_vendor', {
    params: {
      site_id: site_id,
      start_date: start_date,
      end_date: end_date,
      top_N: top_N
    },
    headers: { "instance-id": instance_id }
  })
}

export const getTopSlowSellersOverviewApi = ({
  instance_id,
  site_id,
  type = 'BY_ITEM', //BY_ITEM, BY_VENDOR, BY_CATEGORY
  top_N = 5
}) => {
  if (type === 'BY_ITEM') {
    return callGet('overviews/top_slow_sellers_by_item', {
      params: { top_N: top_N, site_id: site_id },
      headers: { "instance-id": instance_id }
    })
  } else {
    return callGet('overviews/top_slow_sellers_by_group', {
      params: { top_N: top_N, site_id: site_id, item_group: type },
      headers: { "instance-id": instance_id }
    })
  }
}

export const getTopBestSellersOverviewApi = ({
  instance_id,
  site_id,
  type = 'BY_ITEM', //BY_ITEM, BY_VENDOR, BY_CATEGORY
  top_N = 5
}) => {
  if (type === 'BY_ITEM') {
    return callGet('overviews/best_sellers_by_item', {
      params: { top_N: top_N, site_id: site_id },
      headers: { "instance-id": instance_id }
    })
  } else {
    return callGet('overviews/best_sellers_by_group', {
      params: { top_N: top_N, site_id: site_id, item_group: type },
      headers: { "instance-id": instance_id }
    })
  }
}

export const getSalesOverviewApi = ({instance_id, site_id, sale_start_date, sale_end_date}) => {
  return callGet('overviews/sales_volume', {
    params: {
      sale_start_date: sale_start_date,
      sale_end_date: sale_end_date,
      site_id: site_id
    },
    headers: { "instance-id": instance_id }
  })
}

//Forecast Setting
export const getForecastSettingsApi = ({
  instance_id,
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet('settings/forecast', {
    params: {
      page: page,
      page_size: page_size,
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,，]\s*/g, " | ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

export const getForecastSettingByIdApi = ({
  instance_id,
  id
}) => {
  return callGet(`settings/forecast/${id}`, {
    headers: { "instance-id": instance_id }
  })
}

// "forecast_params": {
//   "LAST_SALES": {
//     "average_period_in_day": 0
//   },
//   "SEASONAL": {
//     "yoy_change_limit_percentage": 0
//   },
//   "CONVECT": {
//     "auto_ml": true
//   },
//   "RECENT_SALES_TRENDS": {
//     "period": {
//       "end_period": "2021-12-20",
//       "start_period": "2021-12-20"
//     },
//     "trend_months": 0
//   }
// }
export const updateForecastSettingApi = ({
  instance_id,
  item_id,
  replenishable,
  use_stockout_history,
  custom_forecasting_setting,
  forecast_method, //LAST_SALES SEASONAL CONVECT RECENT_SALES_TRENDS
  forecast_params
}) => {
  return callPatch(`settings/forecast/${item_id}`, {
    ...( replenishable !== null ? { replenishable: replenishable } : null ),
    ...( use_stockout_history !== null ? { use_stockout_history: use_stockout_history } : null ),
    ...( custom_forecasting_setting !== null ? { custom_forecasting_setting: custom_forecasting_setting } : null ),
    ...( forecast_method ? { forecast_method: forecast_method } : null ),
    ...( forecast_params ? { forecast_params: forecast_params } : null )
  }, { headers: { "instance-id": instance_id } })
}

//inventory Setting
export const getInventorySettingsApi = ({
  instance_id,
  site_id,
  search_key_words,
  page = 1,
  page_size = 10
}) => {
  return callGet('settings/inventory', {
    params: {
      page: page,
      page_size: page_size,
      ...( site_id ? { site_id: site_id } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,|，]\s*/g, " or ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

// {
//   "settings": [
//     {
//       "days_of_stock": 0,
//       "item_id": "xxxx",
//       "max_stock": 0,
//       "assembly_time": 0,
//       "unit_of_measurement_STO": 0,
//       "lead_time": 0,
//       "non_replenishable": true,
//       "site_id": "xxxx",
//       "min_safety_stock": 0,
//       "stock_alert": true,
//       "holding_cost": 0
//     }
//   ]
// }
export const batchUpdateInventorySettingApi = ({
  instance_id,
  settings
}) => {
  return callPatch('settings/inventory', {
    settings: settings
  }, { headers: { "instance-id": instance_id } })
}

//forecast sales
export const getForecastSalesApi = ({
  instance_id,
  item_id_list,
  site_id,
  forecast_start_date,
  forecast_end_date,
  group_type = 'MONTHLY'
}) => {
  return callGet('forecasts/forecast_sales/', {
    params: {
      item_id_list: item_id_list instanceof Array ? item_id_list : [item_id_list],
      site_id: site_id,
      group_type: group_type,
      forecast_start_date: forecast_start_date,
      forecast_end_date: forecast_end_date
    },
    headers: { "instance-id": instance_id }
  })
}

export const getForecastSalesByItemApi = ({
  instance_id,
  site_id,
  forecast_start_date,
  forecast_end_date,
  category,
  vendor_id,
  search_key_words,
  group_type = 'MONTHLY', //DAILY, WEEKLY, MONTHLY
  page = 1,
  page_size = 10
}) => {
  return callGet('forecasts/forecast_sales_by_item', {
    params: {
      page: page,
      page_size: page_size,
      site_id: site_id,
      forecast_start_date: forecast_start_date,
      forecast_end_date: forecast_end_date,
      group_type: group_type,
      ...( category ? { category: category } : null ),
      ...( vendor_id ? { vendor_id: vendor_id } : null ),
      ...( search_key_words ? {
        search_key_words: search_key_words.replace(/\s*[,，]\s*/g, " | ")
      } : null )
    },
    headers: { "instance-id": instance_id }
  })
}

// {
//   "sales": [
//     {
//       "group_type": "MONTHLY", //DAILY, WEEKLY, MONTHLY
//       "item_id": "uuid",
//       "site_id": "uuid",
//       "sales_date": "2022-01-01",
//       "forecast_sales": 500,
//       "increase_percentage": 500,
//       "history_sales": 500
//     }
//   ]
// }
export const batchUpdateForecastSalesApi = ({
  instance_id,
  sales
}) => {
  return callPatch('forecasts/batch_update_sales', {
    sales: sales
  }, { headers: { "instance-id": instance_id } })
}

//task
export const createForecastTaskApi = ({instance_id}) => {
  return callPost('forecasts/tasks/', {}, {
    headers: { "instance-id": instance_id }
  })
}

export const createReplenishmentTaskApi = ({instance_id}) => {
  return callPost('replenishments/tasks/', {}, {
    headers: { "instance-id": instance_id }
  })
}

export const createConnectionSyncTaskApi = ({instance_id, connection_id}) => {
  return callPost(`connections/${connection_id}/task`, {}, {
    headers: { "instance-id": instance_id }
  })
}

export const getTaskInfoApi = ({instance_id, task_id}) => {
  return callGet(`tasks/${task_id}`, {
    headers: { "instance-id": instance_id }
  })
}

export const getConnectionTasksApi = ({
  instance_id,
  connection_id,
  page = 1,
  page_size = 5
}) => {
  return callGet(`tasks/connections/${connection_id}`, {
    params: {
      page: page,
      page_size: page_size
    },
    headers: { "instance-id": instance_id }
  })
}

export const checkCurrentTaskStatusApi = ({instance_id}) => {
  return callGet('tasks/check_required_to_run_tasks', {
    headers: { "instance-id": instance_id }
  })
}
