import { changeDefaultInstanceApi, updateInstanceApi, createInstanceApi } from '_api'

const ZONE = 'STORE/ACCOUNT/'

const INIT_ACCOUNT_INFO = ZONE + 'INIT_ACCOUNT_INFO'
const SET_DEFAULT_INSTANCE = ZONE + 'SET_DEFAULT_INSTANCE'
const CREATE_INSTANCE = ZONE + 'CREATE_INSTANCE'
const UPDATE_INSTANCE = ZONE + 'UPDATE_INSTANCE'
const OPEN_CREATE_INSTANCE_MODAL = ZONE + 'OPEN_CREATE_INSTANCE_MODAL'
const CLOSE_CREATE_INSTANCE_MODAL = ZONE + 'CLOSE_CREATE_INSTANCE_MODAL'

const defaultState = {
  accountInfo: null,
  token: null,
  instances: null,
  demo_instances: null,
  default_instance_id: null,
  show_create_instance_modal: false
}

export const initAccountInfo = (data) => {
  return ({type: INIT_ACCOUNT_INFO, data: data})
}

export const setDefaultInstance = ({id, success, fail}) => (dispatch) => {
  changeDefaultInstanceApi({id: id}).then((res) => {
    if(res.status) {
      dispatch({type: SET_DEFAULT_INSTANCE, data: id})
      success && success()
    } else {
      fail && fail()
    }
  })
}

export const createInstance = ({id, name, success, fail }) => (dispatch) => {
  createInstanceApi({name: name}).then((res) => {
    if(res.status) {
      dispatch({type: CREATE_INSTANCE, data: {
        created_at: res.data.created_at,
        instance_id: res.data.instance_id,
        name: res.data.name,
        updated_at: res.data.updated_at,
        connections: (res.data.connections || []).length,
        own: 1
      }})
      success && success(res)
    } else {
      fail && fail(res)
    }
  })
}

export const updateInstance = ({id, name, success, fail }) => (dispatch) => {
  updateInstanceApi({id: id, name: name}).then((res) => {
    if(res.status) {
      dispatch({type: UPDATE_INSTANCE, data: {
        created_at: res.data.created_at,
        instance_id: res.data.instance_id,
        name: res.data.name,
        updated_at: res.data.updated_at,
        connections: (res.data.connections || []).length
      }})
      success && success()
    } else {
      fail && fail()
    }
  })
}

const cal_instances_order_by_default = (instances, default_id) => {
  return instances.map(instance => ({
    created_at: instance.created_at,
    instance_id: instance.instance_id,
    name: instance.name,
    updated_at: instance.updated_at,
    connections: instance.connections || 0,
    own: instance.own || 0,
    default: instance.instance_id === default_id ? 1 : 0
  })).sort((a, b)=> b.default-a.default)
}

export const openCreateInstanceModal = () => {
  return ({type: OPEN_CREATE_INSTANCE_MODAL})
}

export const closeCreateInstanceModal = () => {
  return ({type: CLOSE_CREATE_INSTANCE_MODAL})
}

export const reducer = (state = defaultState, action) => {
  switch(action.type) {
    case INIT_ACCOUNT_INFO:
      return {
        ...state,
        accountInfo: action.data.accountInfo,
        token: action.data.token,
        instances: cal_instances_order_by_default(
          action.data.instances,
          action.data.default_instance_id
        ),
        demo_instances: cal_instances_order_by_default(
          action.data.demo_instances,
          action.data.default_instance_id
        ),
        default_instance_id: action.data.default_instance_id
      }
    case SET_DEFAULT_INSTANCE:
      return {
        ...state,
        instances: cal_instances_order_by_default(
          state.instances,
          action.data
        ),
        default_instance_id: action.data
      }
    case CREATE_INSTANCE:
      return {
        ...state,
        instances: [...state.instances, action.data],
        default_instance_id: state.default_instance_id || action.data.instance_id
      }
    case UPDATE_INSTANCE:
      return {
        ...state,
        instances: state.instances.map(instance => ({
          ...instance,
          ...(instance.instance_id === action.data.instance_id ? {
            ...action.data, own: instance.own || 0
          } : null)
        }))
      }
    case OPEN_CREATE_INSTANCE_MODAL:
      return {
        ...state,
        show_create_instance_modal: true
      }
    case CLOSE_CREATE_INSTANCE_MODAL:
      return {
        ...state,
        show_create_instance_modal: false
      }
    default:
      return state
  }
}
